import {
  NavItem,
  Navbar,
  Icon,
  Button,
  Col,
  CardTitle,
  Card,
  Row,
  CardPanel,
  MediaBox,
  Divider,
  Modal,
  DatePicker,
  CollapsibleItem,
  Collapsible,
  Form, Toast,
} from "react-materialize";
import "materialize-css";
import React, { Component } from "react";
import ListaWzor from "./ListaWzor";
import emailjs from "emailjs-com";
import ReactPlayer from "react-player/youtube";
 import call from "./icons/call.jpg" 
import MetaTags from 'react-meta-tags';
 class MaszynyPionowe extends Component {
  constructor() {
    super();

    this.state = {
      search: "brak",
      idtoshow: null,
    };
  }
  sendEmail(e) {
    //e.preventDefault();

    emailjs
      .sendForm(
        "service_58vohsb",
        "template_acpnc33",
        e.target,
        "user_iiHIuApii5dTFW9DZsq3i"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  handleClick = (event) => {
    let keyid = event.target.id;
    this.setState({ idtoshow: keyid });
    console.log(keyid);
  };

  handleClickWithId = (event, id) => {
    this.setState({ idtoshow: id });
    window.scrollTo(0, 0);
  };

  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };

  OnClinkSearch = (event, id) => {
    this.setState({ search: id });
  };

  render() {
    const items = ListaWzor.filter((data) => {
      if (this.state.search == null) return data;
      else if (
        // data.plname.toLowerCase().includes(this.state.search.toLowerCase())
        // ||
        // data.keyword
        //   .toLowerCase()
        //   .includes(this.state.search.toLowerCase()) ||
        // data.plopakowania
        //   .toLowerCase()
        //   .includes(this.state.search.toLowerCase()) ||
        data.rodzaj.toLowerCase().includes(this.state.search.toLowerCase())
      ) {
        return data;
      }
    }).map((data) => {
      return (
        <div>
          <div>
            <Col m={5} s={12} push="m1">
              <Card
                className="kartyproduktow"
                actions={[
                  <Row>
                    <Col>
                      <Modal
                        actions={[]}
                        className="modaleProdukty"
                        bottomSheet={false}
                        fixedFooter={false}
                        id={data.plname}
                        open={false}
                        options={{
                          dismissible: true,
                          endingTop: "2%",
                          inDuration: 250,
                          onCloseEnd: null,
                          onCloseStart: null,
                          onOpenEnd: null,
                          onOpenStart: null,
                          opacity: 0.5,
                          outDuration: 250,
                          preventScrolling: true,
                          startingTop: "2%",
                        }}
                        trigger={
                          <div className="center">
                            <Button large className="orange " node="button">
                              Wiecej informacji
                            </Button>
                          </div>
                        }
                      >
                        <div>
                          <Row>
                            <Col>
                              <Col m={6} s={12}>
                                {/* <Card className=" "> */}
                                <Row>
                              <div align="center">    {data.imgglowny}</div>
                                    

                                </Row>
                                {/* </Card> */}
                              </Col>{" "}
                              <Col m={6} s={12} push="">
                                <Row>
                                  {/* <Card className=""> */}
                                  <Col m={12} s={12} push="">
                                    <h5>{data.plname}</h5>
                                    <Divider />
                                  </Col>
                                  <Col m={12} s={12} push="">
                                    <h5>Opis:</h5>
                                    <br />
                                    <p align="justify">{data.pldescription}</p>
                                  </Col>
                                  <Col m={12} s={12} push="">
                                    <h5>Opakowania:</h5>
                                    <br />
                                    <p align="justify">{data.plopakowania}</p>
                                  </Col>
                                  <Col m={12} s={12} push="">
                                    <h5>Produkty:</h5>
                                    <br />
                                    <p align="justify">{data.plprodukty}</p>
                                  </Col>
                                  

                                  <Row>
                                    <Row></Row>
                                    <Divider />
                                    <Row>
                                      <div className="center">
                                        <Modal
                                          actions={[
                                            <Button
                                              large
                                              modal="close"
                                              node="button"
                                              waves="orange"
                                              className="orange center"
                                            >
                                              Close
                                            </Button>,
                                          ]}
                                          bottomSheet={false}
                                          fixedFooter={false}
                                          header={data.plname}
                                          id={data.imgglowny}
                                          open={false}
                                          options={{
                                            dismissible: true,
                                            endingTop: "10%",
                                            inDuration: 250,
                                            onCloseEnd: null,
                                            onCloseStart: null,
                                            onOpenEnd: null,
                                            onOpenStart: null,
                                            opacity: 0.5,
                                            outDuration: 250,
                                            preventScrolling: true,
                                            startingTop: "4%",
                                          }}
                                          // root={[object HTMLBodyElement]}
                                          trigger={
                                            <Button
                                              large
                                              className="orange"
                                              node="button"
                                            >
                                              Złóż zapytanie ofertowe
                                            </Button>
                                          }
                                        >
                                          <form
                                            className="contact-form"
                                            onSubmit={this.sendEmail}
                                          >   <input
                                              type="text"
                                              name="from_name"
                                            />
                                            <label>Imię i nazwisko</label>
                                          <input type="text" name="firma" />
                                            <label>Firma</label>
                                           
                                            
                                            <input
                                              value={data.plname}
                                              type="text"
                                              name="maszyna"
                                            /><label>Maszyna</label>
                                          
                                            <input
                                              type="email"
                                              name="user_email"
                                            />{" "}  <label>Email</label>
                                        
                                            <input type="text" name="produkt" />    <label>Państwa produkt</label>
                                            
                                            <input type="text" name="message" /><label>
                                              Wiadomość, np. zapytanie o rodzaj
                                              maszyny dla konkretnego produktu
                                            </label>
                                            <div className="center">
                  <Toast
                    className="white center z-depth-0"
                    
                    options={{
                      classes: "center",
                      html: "Wysłano wiadomość.",
                    }}
                  >
                    <input
                      className="btn-large orange white-text"
                      type="submit"
                      value="wyślij"
                    />
                  </Toast>
              </div>
                                          </form>
                                        </Modal>
                                        <br />
                                        <br />
                                        <a
                                          className="btn-large orange "
                                           aria-label="yourdescription"href="tel:+48123454397"
                                        >
                                          Zadzwon
                                        </a>
                                        <Button
                                          large
                                          className="grey darken-3"
                                          modal="close"
                                          node="button"
                                          waves="green"
                                        >
                                          Zamknij
                                        </Button>
                                      </div>
                                    </Row>
                                  </Row>
                                  {/* </Card> */}
                                </Row>
                              </Col>
                            </Col>
                          </Row>
                        </div>
                      </Modal>
                    </Col>
                    <h5 className="right">{data.plname}</h5>
                  </Row>,
                ]}
              >
                <div align="center">{data.imgglowny}</div>
              </Card>
            </Col>
          </div>
        </div>
      );
    });
    const pickeditem = ListaWzor.filter((data) => {
      if (this.state.idtoshow == null) return null;
      else if (data.id === this.state.idtoshow) {
        return data;
      }
    }).map((data) => {
      return (
        <div>
          <div>
            <Row>
              <Col>
                <Col m={12} s={12} push="">
                  <Row>
                    <Card className="klienci z-depth-3 ">
                      <Row >
                        <h5>{data.plname}</h5>
                        <div align="center">{data.imgglowny}</div>
                        <Divider />
                      </Row>
                      <Row>
                        <h5>Opis:</h5>
                        <br />
                        <p className="pjustify">{data.pldescription}</p>
                      </Row>
                      <Row>
                        <br />
                        <br />
                        <h5>Opakowania:</h5>
                        <br />

                        <img src={data.imgopakowanie1} width="20%" />
                        <img src={data.imgopakowanie2} width="20%" />
                        <img src={data.imgopakowanie3} width="20%" />
                        <img src={data.imgopakowanie4} width="20%" />
                        <img src={data.imgopakowanie5} width="20%" />
                        <p>{data.plopakowania}</p>
                      </Row>
                      <Row>
                        <br />
                        <br />
                        <h5>Produkty:</h5>
                        <br />
                        <p>{data.plprodukty}</p>
                      </Row>
                      <Row>
                        <br />
                        <br />
                        <br />
                        {data.daneTechnicznepl}
                        *Wydajność zależy od produktu, formatu opakowania,
                        jakości i grubości folii oraz rodzaju systemu dozowania
                      </Row>

                      <Row>
                        <Row></Row>
                        <Divider />
                        <Row></Row>
                        <Row></Row>
                        <Row>
                          <div className="center">
                            <Button
                              id={data.plname}
                              className="orange "
                              node="button"
                            >
                              Złóż zapytanie ofertowe
                            </Button>
                          </div>
                        </Row>
                      </Row>
                    </Card>
                  </Row>
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      );
    });

    return (
      <div>
        <MetaTags>
        <meta name="title" content="Paletyzacja Madejpak"/>
<meta name="description" content="Systemy paletyzacji Madejpak pozwalają na automatyzację pracy. W skład takiego systemu wchodzi układ podajników, roboty podające gotowe opakowania na paletę lub paletyzatory oraz owijarki."/>
<meta name="keywords" content="paletyzacja, madejpak, robot, paletyzator, system paletyzacji, podajnik, owijarka, desztapler, magazynek palet, paleta,"/>
<meta name="robots" content="index, follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="revisit-after" content="2 days"/>
<meta name="author" content="Karolina Madej"></meta>
          </MetaTags>
          
        <Row>
          <Col m={5} s={12} push="m1">
          <Collapsible accordion={false}>
            <CollapsibleItem
              expanded={false}
              header="Jak działają systemy paletyzacji?"
           
              node="div"
            >
              <div className="black-text">
                <h5 align="justify"> 
Systemy paletyzacji znajdują zastosowanie w rozmaitych gałęziach przemysłu. Zostały stworzone z myślą o wyeliminowaniu czynnika ludzkiego i zautomatyzowaniu procesów pakowania towarów na palety. Paletyzacja to bardzo istotny element linii technologicznych, który powinien się znaleźć w każdej firmie. 
</h5>
               
              </div>
            </CollapsibleItem>
            <CollapsibleItem
              expanded={false}
              header="Na czym polega robotyzacja paletyzacji?"
            
              node="div"
            ><h5 align="justify"> Paletyzacja różnych towarów (często o znacznych gabarytach lub dużej masie) polega na odpowiednim ułożeniu ich na paletach, które następnie są zabezpieczane folią i przekazywane do magazynowania lub dystrybucji. Proces ten, choć nie jest skomplikowany, to wiąże się ze sporym ryzykiem. Dotyczy ono zarówno nadmiernie obciążonych, wykonujących monotonne, powtarzalne czynności pracowników, jak i paletyzowanych towarów, które wskutek błędu ludzkiego mogą ulec uszkodzeniu. Robotyzacja paletyzacji polega na automatyzacji całego procesu, co jest możliwe dzięki zastosowaniu nowoczesnych maszyn.
            </h5>
            </CollapsibleItem>
            <CollapsibleItem
              expanded={false}
              header="Dlaczego paletyzacja worków i kartonów się opłaca?"
            
              node="div"
            ><h5 align="justify"> 
            Paletyzacja oraz depaletyzacja są procesami czasochłonnymi oraz wiążącymi się z dużym wysiłkiem fizycznym. Dlatego też mogą znacznie obciążyć pracowników zakładu. Dzięki profesjonalnym urządzeniom do automatycznej paletyzacji worków czy kartonów można nie tylko usprawnić sam proces, ale również ograniczyć eksploatację fizyczną pracowników oraz zmniejszyć ryzyko wypadków. Nie ma też konieczności zatrudniania dodatkowych osób do tego zajęcia. Przy pomocy proponowanych przez Madej Pak urządzeń pakowanie palet będzie szybsze i bardziej precyzyjne. Eliminując ludzkie błędy znacznie zwiększają one wydajność oraz opłacalność procesu pakowania.
            Skontaktuj się z naszą firmą i poznaj szczegóły oferowanych przez nas systemów paletyzacji oraz innych urządzeń, takich jak poziome i pionowe maszyny pakujące czy owijarki do palet. Zapraszamy do odwiedzenia stron naszych partnerów Concetti, Essegi oraz GSP.
            </h5>
            </CollapsibleItem>
            </Collapsible>
           </Col>
          <div className="">
            <Col m={2} s={12} push="m1">
              <h5>Zadzwoń lub napisz do nas, pomożemy Ci wybrać odpowiedni produkt.</h5>
            </Col>
            <Col m={1} s={12} push="m1">
              <br />
              <br />
              <br />
              <div>
                <a className="btn-large orange "  aria-label="yourdescription"href="tel:+48123454397">
                  Zadzwoń
                </a>
              </div>
            </Col>
            <Col m={1} s={2} push="m1 s6">
              
              <img
                className="hide-on-small-only"
                src={call}
                width="100%"
              />
            </Col>
          </div>
        </Row>
        <Divider />

<Row><Col s={12} m={10} push="m1">
        <div className='player-wrapper' align="center">
<ReactPlayer
 width='100%'
 height='100%'
 className='react-player'
url="https://youtu.be/XShsul46G2s" />{" "}
</div></Col></Row>

<Divider />

<Row><Col s={12} m={10} push="m1">
        <div className='player-wrapper' align="center">
<ReactPlayer
 width='100%'
 height='100%'
 className='react-player'
url="https://youtu.be/BY-Ihzi7uIQ" />{" "}
</div></Col></Row>
        {/* <Row>{items}</Row> */}
      </div>
 
    );
  }
}

export default MaszynyPionowe;
