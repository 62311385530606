import {

  Button,
  Col,
 
  Card,
  Row,

  Modal,
  Table,

  Toast,
} from "react-materialize";
import "materialize-css";
import React, { Component } from "react";
import emailjs from "emailjs-com";
import MetaTags from 'react-meta-tags';
class Serwis extends Component {
  sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_58vohsb",
        "template_acpnc33",
        e.target,
        "user_iiHIuApii5dTFW9DZsq3i"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  render() {
    return (
      <div>
        <Row>
          <Col m={10} s={12} push="m1">
            <Card>
              <Row>
                <Col l={6} m={12} s={12}>
                  <br />
                  <Row />
                  <Row />

                  <br />
                  <Table>
                    <thead>
                      <tr>
                        <th data-field="id">Dział</th>
                        <th data-field="name">Numer telefonu</th>
                        <th data-field="price">E-Mail</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Części Zamienne</td>
                        <td>+48 12 345 43 97 w. 11</td>
                        <td>
                        czescizamienne@<br/>
                        madejpak.pl<br/>
                          <Modal
                            actions={[
                              <Button
                                flat
                                modal="close"
                                node="button"
                                waves="orange"
                                className="orange"
                              >
                                Close
                              </Button>,
                            ]}
                            bottomSheet={false}
                            fixedFooter={false}
                            header="Części zamienne"
                            id="Modal-3220"
                            open={false}
                            options={{
                              dismissible: true,
                              endingTop: "10%",
                              inDuration: 250,
                              onCloseEnd: null,
                              onCloseStart: null,
                              onOpenEnd: null,
                              onOpenStart: null,
                              opacity: 0.5,
                              outDuration: 250,
                              preventScrolling: true,
                              startingTop: "4%",
                            }}
                            // root={[object HTMLBodyElement]}
                            trigger={
                              <Button className="orange" node="button">
                                Zapytanie
                              </Button>
                            }
                          >
                            <form
                              className="contact-form"
                              onSubmit={this.sendEmail}
                            >
                              {" "}
                              <input type="text" name="from_name" />
                              <label>Imię i nazwisko</label>
                              <input type="text" name="firma" />
                              <label>Firma</label>
                              <input type="text" name="maszyna" />
                              <label>
                                {" "}
                                nazwa maszyny, numer seryjny oraz rok produkcji
                              </label>
                              <input type="email" name="user_email" />{" "}
                              <label>Email</label>
                              <input type="text" name="produkt" />{" "}
                              <label>Państwa produkt</label>
                              <input type="text" name="message" />
                              <label>
                                Wiadomość, np. zapytanie o rodzaj maszyny dla
                                konkretnego produktu
                              </label>
                              <div className="center">
                                <Toast
                                  className="white center z-depth-0"
                                  options={{
                                    classes: "center",
                                    html: "Wysłano wiadomość.",
                                  }}
                                >
                                  <input
                                    className="btn-large orange white-text"
                                    type="submit"
                                    value="wyślij"
                                  />
                                </Toast>
                              </div>
                            </form>
                          </Modal>
                        </td>
                      </tr>
                      <tr>
                        <td>Dział projektów</td>
                        <td>+48 12 345 43 97 w. 13</td>
                        <td>
                        biuro@<br/>
                        madejpak.pl<br/>
                          <Modal
                            actions={[
                              <Button
                                flat
                                modal="close"
                                node="button"
                                waves="orange"
                                className="orange"
                              >
                                Close
                              </Button>,
                            ]}
                            bottomSheet={false}
                            fixedFooter={false}
                            header="Dział projektów"
                            id="Modal-220"
                            open={false}
                            options={{
                              dismissible: true,
                              endingTop: "10%",
                              inDuration: 250,
                              onCloseEnd: null,
                              onCloseStart: null,
                              onOpenEnd: null,
                              onOpenStart: null,
                              opacity: 0.5,
                              outDuration: 250,
                              preventScrolling: true,
                              startingTop: "4%",
                            }}
                            // root={[object HTMLBodyElement]}
                            trigger={
                              <Button className="orange" node="button">
                                Zapytanie
                              </Button>
                            }
                          >
                            <form
                              className="contact-form"
                              onSubmit={this.sendEmail}
                            >
                              {" "}
                              <input type="text" name="from_name" />
                              <label>Imię i nazwisko</label>
                              <input type="text" name="firma" />
                              <label>Firma</label>
                              <input type="text" name="maszyna" />
                              <label>
                                {" "}
                                nazwa maszyny, numer seryjny oraz rok produkcji
                              </label>
                              <input type="email" name="user_email" />{" "}
                              <label>Email</label>
                              <input type="text" name="produkt" />{" "}
                              <label>Państwa produkt</label>
                              <input type="text" name="message" />
                              <label>
                                Wiadomość, np. zapytanie o rodzaj maszyny dla
                                konkretnego produktu
                              </label>
                              <div className="center">
                                <Toast
                                  className="white center z-depth-0"
                                  options={{
                                    classes: "center",
                                    html: "Wysłano wiadomość.",
                                  }}
                                >
                                  <input
                                    className="btn-large orange white-text"
                                    type="submit"
                                    value="wyślij"
                                  />
                                </Toast>
                              </div>
                            </form>
                          </Modal>
                        </td>
                      </tr>
                      <tr>
                        <td>Serwis i Tele Serwis</td>
                        <td>+48 12 345 43 97 w. 14</td>
                        <td>
                        serwis@<br/>
                        madejpak.pl<br/>

                          <Modal
                            actions={[
                              <Button
                                flat
                                modal="close"
                                node="button"
                                waves="orange"
                                className="orange"
                              >
                                Close
                              </Button>,
                            ]}
                            bottomSheet={false}
                            fixedFooter={false}
                            header="Serwis"
                            id="Modal-220das"
                            open={false}
                            options={{
                              dismissible: true,
                              endingTop: "10%",
                              inDuration: 250,
                              onCloseEnd: null,
                              onCloseStart: null,
                              onOpenEnd: null,
                              onOpenStart: null,
                              opacity: 0.5,
                              outDuration: 250,
                              preventScrolling: true,
                              startingTop: "4%",
                            }}
                            // root={[object HTMLBodyElement]}
                            trigger={
                              <Button className="orange" node="button">
                                Zapytanie
                              </Button>
                            }
                          >
                            <form
                              className="contact-form"
                              onSubmit={this.sendEmail}
                            >
                              {" "}
                              <input type="text" name="from_name" />
                              <label>Imię i nazwisko</label>
                              <input type="text" name="firma" />
                              <label>Firma</label>
                              <input type="text" name="maszyna" />
                              <label>
                                {" "}
                                nazwa maszyny, numer seryjny oraz rok produkcji
                              </label>
                              <input type="email" name="user_email" />{" "}
                              <label>Email</label>
                              <input type="text" name="produkt" />{" "}
                              <label>Państwa produkt</label>
                              <input type="text" name="message" />
                              <label>
                                Wiadomość, np. zapytanie o rodzaj maszyny dla
                                konkretnego produktu
                              </label>
                              <div className="center">
                                <Toast
                                  className="white center z-depth-0"
                                  options={{
                                    classes: "center",
                                    html: "Wysłano wiadomość.",
                                  }}
                                >
                                  <input
                                    className="btn-large orange white-text"
                                    type="submit"
                                    value="wyślij"
                                  />
                                </Toast>
                              </div>
                            </form>
                          </Modal>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Row></Row>
                  <a
                    className="btn-large orange center "
                     aria-label="yourdescription"href="tel:+48123454397"
                  >
                    zadzwon do centrali
                  </a>
                </Col>
                <Col l={6} m={12} s={12}>
                <br/><br/><br/><br/>
                  <img
                    className="z-depth-2"
                    src="https://images.unsplash.com/photo-1597423498219-04418210827d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=967&q=80"
                    width="100%"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Serwis;
